import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  jobDetails,
  myJobDetails,
  updateJobInviteStatus,
  updateJobProposalStatus,
} from "../../../API/JobsApi";
import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "../../../shared/Toasters/toaster";
import Loader from "../../../utils/Loader";
import { formatDate } from "../../../utils/Dates";
import { Button } from "react-bootstrap";
import SendProposal from "../../../components/model/SendProposal";
import { EmptyComponent } from "../../../shared/Empty/Empty";
import { getUserInfo } from "../../../common/getUserInfo";
import { Status, UserTypes } from "../../../constants/constant";
import { Confirmation } from "../../../shared/Modal/Confirmation";
import { createChat } from "../../../API/ChatApi";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import ReviewComponent from "../../../shared/Review/ReviewComponent";
import { fetchJobDetails } from "../../../API/AdminApi";
import { InformationModal } from "../../../shared/Modal/Modal";
import { AdditionalInfoRenderer } from "../../../utils/AdditionalInfoRenderer";

const JobDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [jobDetail, setJobDetail] = useState(null);
  const [showProposalModal, setShowProposalModal] = useState(false);
  const [confirmationShow, setConfirmationShow] = useState(false);
  const [currentProposalId, setCurrentProposalId] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const userInfo = getUserInfo();
  const guid = uuidv4();
  const navigate = useNavigate();

  const pathName = location.pathname.split("/")[1];

  const getJobDetails = async (id) => {
    setStatus(Status.PENDING);
    try {
      let data;
      if (pathName === "my-jobs") {
        data = await myJobDetails(id);
        if (data) {
          setJobDetail(data);
          setStatus(Status.IDLE);
        }
      } else {
        const value = {
          jobId: id,
          userId: userInfo?._id,
        };
        data =
          userInfo?.userType === "admin"
            ? await fetchJobDetails({ jobId: id })
            : await jobDetails(value);
        if (data) {
          setJobDetail(data);
          setStatus(Status.IDLE);
        }
      }
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const createNewChat = async (photographerId) => {
    setStatus(Status.PENDING);
    try {
      const value = {
        jobId: id,
        rId: photographerId,
        // chatId: guid,
        message: "Hi",
      };
      const { _id } = await createChat(value);
      navigate(`/messages/?${_id}`, { state: jobDetail });
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  useEffect(() => {
    getJobDetails(id);
  }, [id]);

  const RenderSubmitProposalComponent = () => {
    if (!userInfo) {
      return (
        <div className="d-grid">
          <Link to="/login/photographer" className="ud-btn btn-thm">
            Submit a Proposal
            <i className="fal fa-arrow-right-long"></i>
          </Link>
        </div>
      );
    }
    if (userInfo && userInfo.profileStatus !== Status.COMPLETED) {
      return (
        <div className="d-grid">
          <button className="ud-btn btn-thm" onClick={() => setShowInfo(true)}>
            Submit a Proposal
            <i className="fal fa-arrow-right-long"></i>
          </button>
        </div>
      );
    }
    if (
      userInfo &&
      userInfo.userType === UserTypes.PHOTOGRAPHER &&
      jobDetail?.showProposal &&
      !jobDetail?.invitationReceived &&
      jobDetail?.invitationStatus !== "open"
    ) {
      return (
        <div className="d-grid">
          <Button
            className="ud-btn btn-thm"
            onClick={() => setShowProposalModal(true)}
          >
            Submit a Proposal
            <i className="fal fa-arrow-right-long"></i>
          </Button>
        </div>
      );
    } else if (
      userInfo &&
      userInfo.userType === UserTypes.PHOTOGRAPHER &&
      jobDetail?.showProposal &&
      jobDetail?.invitationReceived &&
      jobDetail?.invitationStatus === "open"
    ) {
      return (
        <div className="mt15 justify-content-between">
          <div className="d-grid">
            <Button
              className="ud-btn btn-thm"
              onClick={() => UpdateInviteStatus("accepted")}
            >
              Accept
              <i className="fal fa-arrow-right-long"></i>
            </Button>
          </div>
          <div className="d-grid mt10">
            <Button
              className="ud-btn btn-thm"
              onClick={() => UpdateInviteStatus("declined")}
            >
              Decline
              <i className="fal fa-arrow-right-long"></i>
            </Button>
          </div>
        </div>
      );
    }
  };

  const changeJobProposalStatus = async (status, proposalId) => {
    setStatus(Status.PENDING);
    const formData = {
      proposalId: proposalId,
      status: status,
    };
    try {
      await updateJobProposalStatus(formData);
      SuccessToaster("Status updated successfully");
      setStatus(Status.IDLE);
      getJobDetails(id);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const UpdateInviteStatus = async (status) => {
    setStatus(Status.PENDING);
    const formData = {
      invitationId: jobDetail?.invitationId,
      status: status,
    };
    try {
      await updateJobInviteStatus(formData);
      SuccessToaster("Status updated successfully");
      if (status === "accepted") {
        setShowProposalModal(true);
      }
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const handleMessageClick = (photographerId) => {
    const jobProposalForUser = jobDetail?.jobProposals?.find(
      (proposal) => proposal.userId === photographerId
    );
    if (jobProposalForUser?.chatId) {
      navigate(`/messages/?q=${jobProposalForUser?.chatId}`, {
        state: jobDetail,
      });
    } else {
      createNewChat(photographerId);
    }
  };

  const generateButtonsBasedOnStatus = (status, proposalId, photographerId) => {
    const handleConfirmationOk = () => {
      changeJobProposalStatus(currentStatus, currentProposalId);
      setConfirmationShow(false);
    };

    const buttonsMap = {
      pending: (
        <div className="d-flex gap-2">
          <button
            className="ud-btn btn-thm accept"
            onClick={() => {
              setConfirmationShow(true);
              setCurrentProposalId(proposalId);
              setCurrentStatus(Status.ACCEPTED);
            }}
          >
            Accept
          </button>
          <button
            className="login-info home18-sign-btn px15 ml10 bdr1-dark"
            style={{ width: "80px", borderRadius: "5px" }}
            onClick={() => {
              setConfirmationShow(true);
              setCurrentProposalId(proposalId);
              setCurrentStatus(Status.REJECTED);
            }}
          >
            Reject
          </button>
        </div>
      ),
      accepted: jobDetail?.status !== "completed" && (
        <div className="d-flex gap-2">
          <div className="col-auto">
            <button
              className="mr15 ud-btn add-joining home20-join-btn text-white"
              onClick={() => handleMessageClick(photographerId)}
            >
              Start Chat
            </button>
          </div>
        </div>
      ),
      rejected: (
        <div className="d-flex gap-2">
          <button
            className="ud-btn btn-thm accepted"
            style={{
              padding: "7px 20px",
              backgroundColor: "grey",
              border: "1px solid grey",
            }}
            disabled
          >
            Rejected
          </button>
        </div>
      ),
    };

    return (
      <>
        {buttonsMap[status]}
        <Confirmation
          show={confirmationShow}
          onClose={() => setConfirmationShow(false)}
          onConfirmation={handleConfirmationOk}
        />
      </>
    );
  };

  const handleProfileClick = (userId) => {
    navigate(`/photographers/${userId}`, { replace: true });
  };

  // const reviews = [
  //   {
  //     jobId: '65784b5cc21566cf94a7e8bb',
  //     userId: '65744b7a56064bee22ba87de',
  //     message:
  //       'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available',
  //     date: '2023-12-27T17:33:50.160Z',
  //     rating: 5,
  //     _id: '658c5ffe4153d8210da8ad7a',
  //   },
  //   {
  //     jobId: '6575af676ffc0e9c44d4b4ca',
  //     userId: '65744b7a56064bee22ba87de',
  //     message:
  //       'In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available',
  //     date: '2023-12-31T10:15:31.292Z',
  //     rating: 4,
  //     _id: '65913f4334a05c00892a5161',
  //   },
  // ];

  return (
    <>
      <SendProposal
        budgetType={jobDetail?.budgetType}
        showModal={showProposalModal}
        setShowProposalModal={setShowProposalModal}
        jobId={id}
      />
      <InformationModal
        show={showInfo}
        onHide={() => setShowInfo(false)}
        isAuth={true}
        message="Your profile is currently under review. Once approved, you will be able to submit the proposals."
      />
      <div className="body_content">
        {/* <!-- Breadcumb Sections --> */}
        {!userInfo?.userType === "admin" && (
          <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-sm-8 col-lg-10">
                  <div className="breadcumb-style1 mb10-xs">
                    <div className="breadcumb-list">
                      <Link to="/">Home</Link>
                      <Link to="/jobs">Jobs</Link>
                      <Link to={`jobs/${id}`}>Detail</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {/* <!-- Service Details --> */}
        {status === Status.PENDING && !jobDetail ? (
          <Loader />
        ) : (
          <section className="pt10">
            <div className="container">
              <div className="row wrap">
                <div className="col-lg-8 mt25">
                  <div className="column">
                    <div className="cta-service-v1 freelancer-single-v1 pt60 pb60 bdrs16 position-relative overflow-hidden d-flex align-items-center">
                      <img
                        className="left-top-img wow zoomIn"
                        src="https://creativelayers.net/themes/freeio-html/images/vector-img/left-top.png"
                        alt=""
                      />
                      <img
                        className="right-bottom-img wow zoomIn"
                        src="https://creativelayers.net/themes/freeio-html/images/vector-img/right-bottom.png"
                        alt=""
                      />
                      <div className="row wow fadeInUp">
                        <div className="col-xl-12">
                          <div className="position-relative pl60 pl20-sm">
                            <h2>{jobDetail?.title}</h2>
                            <div className="list-meta mt15">
                              <p className="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                                <i className="flaticon-place vam fz20 text-thm2 me-2"></i>{" "}
                                {jobDetail?.location || "NA"}
                              </p>
                              {/* <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                                <i className="flaticon-calendar text-thm2 vam fz20 me-2"></i>{' '}
                                {formatDate(jobDetail?.creationDate)}
                              </p>
                              <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                                <i className="flaticon-calendar text-thm2 vam fz20 me-2"></i>{' '}
                                {formatDate(jobDetail?.jobDateTime)}
                              </p>
                              <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                                <i className="flaticon-calendar text-thm2 vam fz20 me-2"></i>{' '}
                                {formatDate(jobDetail?.jobEndDateTime)}
                              </p> */}
                              {/* <p className="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                                <i className="flaticon-website text-thm2 vam fz20 me-2"></i>{' '}
                                {jobDetail?.appliedForJob?.length}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt30">
                      {/* <div className="col-sm-6 col-xl-4">
                      <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div className="icon flex-shrink-0">
                          <span className="flaticon-notification-1"></span>
                        </div>
                        <div className="details">
                          <h5 className="title">Seller Type</h5>
                          <p className="mb-0 text">Company</p>
                        </div>
                      </div>
                    </div> */}
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-dollar"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Budget type</h5>
                            <p className="mb-0 text text-capitalize">
                              {jobDetail?.budgetType}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-calendar"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Date Posted</h5>
                            <p className="mb-0 text">
                              {formatDate(jobDetail?.creationDate)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="fa-light fa-users"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Photographers Required</h5>
                            <p className="mb-0 text">
                              {jobDetail?.noOfPhotographers}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-fifteen"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Job Duration</h5>
                            <p className="mb-0 text text-capitalize">
                              {jobDetail?.jobDuration} {jobDetail?.durationType}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-calendar"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Job Start Date</h5>
                            <p className="mb-0 text">
                              {formatDate(jobDetail?.jobDateTime)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-xl-4">
                        <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                          <div className="icon flex-shrink-0">
                            <span className="flaticon-calendar"></span>
                          </div>
                          <div className="details">
                            <h5 className="title">Job End Date</h5>
                            <p className="mb-0 text">
                              {formatDate(jobDetail?.jobEndDateTime)}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-sm-6 col-xl-4">
                      <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div className="icon flex-shrink-0">
                          <span className="flaticon-translator"></span>
                        </div>
                        <div className="details">
                          <h5 className="title">Languages</h5>
                          <p className="mb-0 text">20</p>
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="col-sm-6 col-xl-4">
                      <div className="iconbox-style1 contact-style d-flex align-items-start mb30">
                        <div className="icon flex-shrink-0">
                          <span className="flaticon-goal"></span>
                        </div>
                        <div className="details">
                          <h5 className="title">English Level</h5>
                          <p className="mb-0 text">Professional</p>
                        </div>
                      </div>
                    </div> */}
                    </div>
                    <div className="service-about">
                      <h4>Description</h4>
                      <p className="text mb30 fz16">{jobDetail?.description}</p>
                      {/* <hr className="opacity-100 mb60 mt60" />
                    <h4 className="mb30">Attachments</h4>
                    <div className="row">
                      <div className="col-6 col-lg-3">
                        <div className="project-attach">
                          <h6 className="title">Project Brief</h6>
                          <p>PDF</p>
                          <span className="icon flaticon-page"></span>
                        </div>
                      </div>
                      <div className="col-6 col-lg-3">
                        <div className="project-attach">
                          <h6 className="title">Project Brief</h6>
                          <p>PDF</p>
                          <span className="icon flaticon-page"></span>
                        </div>
                      </div>
                    </div> */}
                      <hr className="opacity-100 mb60 mt30" />
                      <h4 className="mb30">Skills Required</h4>
                      <div className="mb60">
                        <div className="tag list-inline-item mb-2 mb-xl-0 mr10">
                          {jobDetail?.category}
                        </div>
                        {!jobDetail?.category && (
                          <EmptyComponent
                            description="No Skill Found."
                            type={true}
                          />
                        )}
                      </div>
                      <hr className="opacity-100 mb60 mt30" />
                      <h4 className="mb30">Additional Information</h4>
                      <div className="row mb60">
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between mb-3">
                            <div className="fs500 fz16">
                              Are Multiple Cameras Needed?:
                            </div>
                            <div className="fz16">
                              {jobDetail?.additionInfo?.multipleCameraRequired
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                          {/* <div className="mb-3"> */}
                          <div className="d-flex justify-content-between mb-3">
                            <div className="fs500 fz16">
                              Videography Needed?:
                            </div>
                            <div className="fs500 fz16">
                              {jobDetail?.additionInfo?.videographyNeeded
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between mb-3">
                            <div className="fz16">Digital Files Required?:</div>
                            <div className="fz16">
                              {jobDetail?.additionInfo?.digitalFilesRequired
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between mb-3">
                            <div className="fz16">
                              Business Insurance Required?:
                            </div>
                            <div className="fz16">
                              {jobDetail?.additionInfo
                                ?.businessInsuranceRequired
                                ? "Yes"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      {userInfo?.userType === "user" && (
                        <>
                          <hr className="opacity-100 mb60" />
                          <h4 className="mb30">
                            Project Proposals ({jobDetail?.jobProposals?.length}
                            )
                          </h4>
                          {jobDetail?.jobProposals?.length > 0 ? (
                            <>
                              <div className="row">
                                {jobDetail?.jobProposals?.map((proposal) => (
                                  <div
                                    className="col-md-6 col-lg-12"
                                    key={proposal?._id}
                                  >
                                    <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-start">
                                      <div className="col-xl-10 px-0">
                                        <div className="d-lg-flex">
                                          <div
                                            className="thumb w90 position-relative rounded-circle mb15-md"
                                            onClick={() =>
                                              handleProfileClick(
                                                proposal?.userId
                                              )
                                            }
                                          >
                                            <img
                                              className="rounded-circle mx-auto"
                                              style={{
                                                width: "100px",
                                                height: "100px", // Set a fixed height
                                                objectFit: "cover", // Maintain aspect ratio and cover the container
                                                borderRadius: "50%", // Make the image round by setting border radius to 50%
                                              }}
                                              src={
                                                proposal?.profilePicture ||
                                                "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                              }
                                              alt="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                                            />
                                            <span className="online"></span>
                                          </div>
                                          <div className="details ml20 ml0-md mb15-md">
                                            <h5
                                              className="title mb-1"
                                              onClick={() =>
                                                handleProfileClick(
                                                  proposal?.userId
                                                )
                                              }
                                            >
                                              {proposal?.proposalUserName}
                                            </h5>
                                            <div className="review mb20">
                                              <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                                <i className="fas fa-star fz10 review-color pr10"></i>{" "}
                                                <span className="dark-color">
                                                  {proposal?.rating}
                                                </span>{" "}
                                                ({proposal?.totalReviews}{" "}
                                                reviews)
                                              </p>
                                              <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                                <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 bdrn-md pl15 pl0-md bdrn-xs"></i>{" "}
                                                {formatDate(
                                                  proposal?.creationDate ||
                                                    "2023-11-07T17:25:35.030Z"
                                                )}
                                              </p>
                                            </div>
                                            <p className="text mb-0 fz16">
                                              <AdditionalInfoRenderer
                                                text={proposal?.proposalMessage}
                                                maxLength={150}
                                              />
                                              {}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-xl-2 px-0">
                                        <div className="text-lg-center text-xl-end mt-0 mt-lg-2 mt-xl-0">
                                          {proposal?.budgetType === "hourly" ? (
                                            <h4>
                                              ${proposal?.hourlyRate}/Hour
                                            </h4>
                                          ) : (
                                            <h4>${proposal?.budget}</h4>
                                          )}
                                          {/* <p className="text mb-0">
            In {proposal?.estimatedHours} Hours
          </p> */}
                                        </div>
                                        <div className="col-xl-2 px-0 d-flex align-items-center justify-content-end ml100 mt20">
                                          {generateButtonsBasedOnStatus(
                                            proposal?.status,
                                            proposal?._id,
                                            proposal?.userId
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <div className="mt90">
                              <EmptyComponent
                                description="No Proposals Found."
                                type={true}
                              />
                            </div>
                          )}
                          <hr className="opacity-100 mb60" />
                          <h4 className="mb30">Reviews</h4>
                          {jobDetail?.reviews?.length > 0 ? (
                            <ReviewComponent
                              reviews={jobDetail?.reviews}
                              reviewersDetails={jobDetail?.jobProposals?.[0]}
                              jobDetail={jobDetail}
                              type="job"
                              reviewer="photographer"
                            />
                          ) : (
                            <div className="mt90">
                              <EmptyComponent
                                description="No Reviews Found."
                                type={true}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {jobDetail?.jobProposal?.length > 0 &&
                        userInfo?.userType === "photographer" && (
                          <>
                            <hr className="opacity-100 mb60" />
                            <h4 className="mb30">Your Proposal</h4>
                            <div className="row">
                              {jobDetail?.jobProposal?.map((proposal) => (
                                <div
                                  className="col-md-6 col-lg-12"
                                  key={proposal?._id}
                                >
                                  <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-start">
                                    <div className="col-xl-10 px-0">
                                      <div
                                        className="d-lg-flex"
                                        // onClick={() =>
                                        //   handleProfileClick(proposal?.userId)
                                        // }
                                      >
                                        <div className="thumb w90 position-relative rounded-circle mb15-md">
                                          <img
                                            className="rounded-circle mx-auto"
                                            style={{
                                              width: "60px",
                                              height: "60px", // Set a fixed height
                                              objectFit: "cover", // Maintain aspect ratio and cover the container
                                              borderRadius: "50%", // Make the image round by setting border radius to 50%
                                            }}
                                            src={
                                              userInfo?.profilePicture ||
                                              "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                            }
                                            alt="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                                          />
                                        </div>
                                        <div className="details ml20 ml0-md mb15-md">
                                          <div className="review mb20">
                                            <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                              <i className="flaticon-30-days fz16 vam text-thm2 me-1 pl0-md bdrn-xs"></i>{" "}
                                              {formatDate(
                                                proposal?.creationDate ||
                                                  "2023-11-07T17:25:35.030Z"
                                              )}
                                            </p>
                                          </div>
                                          <p className="text mb-0 fz16">
                                            <AdditionalInfoRenderer
                                              text={proposal?.proposalMessage}
                                              maxLength={150}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-2 px-0">
                                      <div className="text-lg-center text-xl-end mt-0 mt-lg-2 mt-xl-0">
                                        {proposal?.budgetType === "hourly" ? (
                                          <h4>${proposal?.hourlyRate}/Hour</h4>
                                        ) : (
                                          <h4>${proposal?.budget}</h4>
                                        )}
                                        {/* <p className="text mb-0">
                                        In {proposal?.estimatedHours} Hours
                                      </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <hr className="opacity-100 mb60" />
                            <h4 className="mb30">Reviews</h4>
                            {jobDetail?.reviews?.length > 0 ? (
                              <ReviewComponent
                                reviews={jobDetail?.reviews}
                                reviewersDetails={jobDetail?.userDetail}
                                jobDetail={jobDetail}
                                type="job"
                                reviewer="job-poster"
                              />
                            ) : (
                              <div className="mt90">
                                <EmptyComponent
                                  description="No Data Found."
                                  type={true}
                                />
                              </div>
                            )}
                          </>
                        )}

                      {/* {userInfo?.userType === 'photographer' &&
                        jobDetail?.reviews?.length > 0 && (
                          <ReviewComponent
                            reviews={jobDetail?.reviews}
                            reviewersDetails={jobDetail?.userDetail}
                            jobDetail={jobDetail}
                            type="job"
                            reviewer="job-poster"
                          />
                        )} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="column">
                    <div className="blog-sidebar ms-lg-auto mt25">
                      <div className="price-widget pt25 bdrs8">
                        <h3 className="widget-title">${jobDetail?.jobRate}</h3>
                        <p className="text text-capitalize fz14">
                          {jobDetail?.budgetType}
                        </p>
                        {/* {userInfo && userInfo?.userType === 'photographer' && (
                          <div className="d-grid">
                            <Button
                              className="ud-btn btn-thm"
                              onClick={() => setShowProposalModal(true)}>
                              Submit a Proposal
                              <i className="fal fa-arrow-right-long"></i>
                            </Button>
                          </div>
                        )} */}
                        <RenderSubmitProposalComponent />
                      </div>
                      <div className="freelancer-style1 service-single mb-0 bdrs8">
                        <h4>About User</h4>
                        <div className="wrapper d-flex align-items-center mt20">
                          <div className="thumb position-relative mb25">
                            <img
                              className="rounded-circle mx-auto"
                              src="images/team/client-1.png"
                              alt=""
                            />
                          </div>
                          <div className="ml20">
                            <h6 className="title mb-1">
                              {jobDetail?.userDetail?.location || "-"}
                            </h6>
                            {/* <p className="mb-0">
                              {jobDetail?.userDetail?.email}
                            </p> */}
                            <div className="review">
                              <p>
                                <i className="fas fa-star fz10 review-color pr10"></i>
                                <span className="dark-color">
                                  {jobDetail?.userDetail?.userRating}
                                </span>{" "}
                                ({jobDetail?.userDetail?.totalReview} reviews)
                              </p>
                            </div>
                          </div>
                        </div>
                        <hr className="opacity-100" />
                        <div className="details">
                          <div className="fl-meta d-flex align-items-center justify-content-between">
                            <div className="meta fw500 text-start">
                              <h6>Members since</h6>
                              <br />
                              <span className="fz16 fw400">
                                {formatDate(
                                  jobDetail?.userDetail?.creationDate ||
                                    "2023-12-04T19:55:25.912Z"
                                )}
                              </span>
                            </div>
                            <div className="meta fw500 text-start">
                              <h6>Photographers Hired</h6>
                              <br />
                              <span className="fz16 fw400">
                                {jobDetail?.userDetail?.totalPhotographersHired}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default JobDetails;
