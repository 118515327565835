import { useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import { useEffect, useState } from 'react';
import { Status } from '../../constants/constant';
import { jobProposalList, updateJobStatus } from '../../API/JobsApi';
import { ErrorToaster, SuccessToaster } from '../Toasters/toaster';
import { reviewJobPoster } from '../../API/PhotographerApi';

export function FeedBackModal(props) {
  const { show, onClose, jobId, reviewer, jobPosterId } = props;
  const [status, setStatus] = useState('');
  const [ratingValue, setRatingValue] = useState(0);
  const [proposalList, setProposalList] = useState([]);

  const getJobProposals = async () => {
    setStatus(Status.PENDING);
    const formData = {
      jobId: jobId,
      status: Status.ACCEPTED,
    };
    try {
      const res = await jobProposalList(formData);
      setProposalList(res);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };

  const changeJobStatus = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await updateJobStatus(formData);
      SuccessToaster('Job closed successfully');
      setStatus(Status.IDLE);
      onClose();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };

  const giveJobPosterFeedBack = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await reviewJobPoster(formData);
      SuccessToaster('Feedback submitted successfully.');
      setStatus(Status.IDLE);
      onClose();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster('Something went wrong.');
    }
  };

  useEffect(() => {
    if (jobId) {
      getJobProposals();
    }
  }, [jobId]);

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    if (reviewer !== 'photographer') {
      const formData = {
        jobId: jobId,
        status: Status.COMPLETED,
        review: {
          photographerId: data?.photographer,
          rating: ratingValue,
          message: data?.feedback,
        },
      };
      await changeJobStatus(formData);
    } else {
      const formData = {
        jobId: jobId,
        userId: jobPosterId,
        rating: ratingValue,
        message: data?.feedback,
      };
      await giveJobPosterFeedBack(formData);
    }
  };

  return (
    <Modal centered size="lg" backdrop="static" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            {reviewer !== 'photographer' && (
              <div className="col-sm-12 d-flex align-items-center">
                <label className="heading-color ff-heading fw500 mb10 fz16">
                  Select Photographer
                </label>
                <div
                  className="bootselect-multiselect"
                  style={{
                    height: '50px',
                    padding: '0px 13px 0px',
                  }}>
                  <select
                    className="form-control"
                    {...register('photographer', {
                      required: 'Photographer is required',
                    })}
                    style={{
                      marginLeft: '10px',
                      height: '40px',
                      padding: '0px 13px 0px',
                      width: '350px',
                    }}>
                    <option value="" selected disabled>
                      Select Photographer
                    </option>
                    {proposalList.map((photographer, index) => (
                      <option key={index} value={photographer?.reviewerId}>
                        {photographer?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="form-group">
            <div className="mb10 mt10">
              <label className="heading-color ff-heading fw500 mb10">
                Rating:
              </label>
              {/* Include your rating component here and register it */}
              <Rating
                value={ratingValue}
                onClick={(value) => setRatingValue(value)}
                size={25}
                transition
                allowHalfIcon
                style={{ marginLeft: '10px' }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb10 mt10">
              <label className="heading-color ff-heading fw500 mb10">
                Feedback:
              </label>
              {/* Use register for your textarea */}
              <textarea
                {...register('feedback')}
                cols="10"
                rows="2"
                placeholder="Write your feedback..."
              />
            </div>
          </div>

          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn payment-btn-cls shadow"
              style={{ border: '1px solid black' }}
              onClick={onClose}>
              Close
            </button> */}

            <button type="submit" className="ud-btn btn-thm feedback">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
