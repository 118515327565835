import React, { useState } from "react";
import "./OtpVerification.css";
import OtpInput from "react-otp-input";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import { ErrorToaster, SuccessToaster } from "../Toasters/toaster";
import {
  myDetail,
  resendOtp,
  updateUserEmailVerify,
  updateUserMobileVerify,
} from "../../API/UserApi";
import { Modal } from "react-bootstrap";
import { mobileVerifyOtp, verifyOtp } from "../../API/AuthApi";
import { getToken } from "../../common/getToken";
import { Status } from "../../constants/constant";
import ProfileRegistration from "../../features/user/components/ProfileRegistration/ProfileRegistration";

const OtpVerificationModal = (props) => {
  const [code, setCode] = useState("");
  const { userData, handleClose, show, setShow, setShowEditModal } = props;
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const token = getToken();

  console.log("userData", userData);

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      setUserInfo(data);
      localStorage.setItem("currentUser", JSON.stringify(data));
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const handleChange = (code) => setCode(code);

  const updatedUserEmailVerify = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await updateUserEmailVerify({
        email: userData.email,
        otp: parseInt(code),
      });
      if (res) {
        setShow(false);
        setShowEditModal(false);
        SuccessToaster(res);
        myDetails();
        window.location.reload();
      }
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const updatedUserMobileVerify = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await updateUserMobileVerify({
        mobile: userData.mobile,
        otp: parseInt(code),
      });
      if (res) {
        setShow(false);
        setShowEditModal(false);
        SuccessToaster(res);
        myDetails();
        window.location.reload();
      }
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const verifyEmailOtp = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await verifyOtp({ ...userData, otp: parseInt(code) });
      setStatus("idle");
      localStorage.setItem("accessToken", res);
      if (["photographer", "agency"].includes(userData?.userType)) {
        setShowModal(true);
        await myDetails();
      } else {
        navigate("/photographers");
        await myDetails();
      }
    } catch (error) {
      setStatus("rejected");
      ErrorToaster("Something went wrong.");
      setError(error?.message);
    }
  };

  const verifyMobileOtp = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await mobileVerifyOtp({ ...userData, otp: parseInt(code) });
      setStatus("idle");
      localStorage.setItem("accessToken", res);
      await myDetails();
      navigate("/profile");
    } catch (error) {
      setStatus("rejected");
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };

  const handleVerifyOtp = async () => {
    if (props?.type === "email") {
      updatedUserEmailVerify();
    } else if (props?.type === "mobile") {
      updatedUserMobileVerify();
    } else if (props?.type === "mobileLogin") {
      verifyMobileOtp();
    } else {
      verifyEmailOtp();
    }
  };

  function getModalContent(type) {
    switch (type) {
      case "email":
        return {
          heading: "Verify Updated Email ID",
          subHeading: "Please Verify Your Email Address",
          message:
            "We have sent you a One-Time Password (OTP) to the email address you provided. Please enter the OTP here to verify and proceed to the next step.",
        };
      case "mobile":
        return {
          heading: "Verify Updated Mobile Number",
          subHeading: "Please Verify Your Mobile Number",
          message:
            "We have sent you a One-Time Password (OTP) to the mobile you provided. Please enter the OTP here to verify and proceed to the next step.",
        };
      case "mobileLogin":
        return {
          heading: "Verify Mobile Number",
          subHeading: "Please Verify Your Mobile Number",
          message:
            "We have sent you a One-Time Password (OTP) to the mobile you provided. Please enter the OTP here to verify and proceed to the next step.",
        };
      default:
        return {
          heading: "Verify Your Email ID",
          subHeading: "Please Verify Your Email Address",
          message:
            "We have sent you a One-Time Password (OTP) to the email address you provided. Please enter the OTP here to verify and proceed to the next step.",
        };
    }
  }

  const handleResendClick = async () => {
    setStatus(Status.PENDING);
    const { fullName, email, userType } = userData || {};
    try {
      const { message } = await resendOtp({
        fullName,
        email,
        userType,
      });
      setStatus("idle");
      SuccessToaster(message);
    } catch (error) {
      setStatus("rejected");
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  };

  const isSuccess = true;
  return (
    <>
      {token || (userInfo && <Navigate to="/profile" replace={true} />)}
      {showModal && (
        <ProfileRegistration
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <Modal
        {...props}
        size="large"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {getModalContent(props?.type)?.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <button
            type="button"
            className="btn-close"
            aria-label="Close"
            // onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '1.5rem',
            }}></button> */}
          <div className="row justify-content-center">
            <div
              className="col-12 col-md-6 col-lg-4"
              style={{ minWidth: "600px" }}
            >
              <div>
                <div className=" p-5 text-center">
                  <h4>{getModalContent(props?.type)?.subHeading}</h4>
                  <p>{getModalContent(props?.type)?.message}</p>
                  <div className="otp-field mb-4">
                    <OtpInput
                      value={code}
                      onChange={handleChange}
                      numInputs={6}
                      renderSeparator={<span style={{ width: "10px" }}></span>}
                      isInputNum={true}
                      shouldAutoFocus={true}
                      inputStyle={{
                        height: "45px",
                        width: "42px",
                        borderRadius: "6px",
                        outline: "none",
                        fontSize: "1.125rem",
                        textAlign: "center",
                        border: "1px solid #ddd",
                      }}
                      focusStyle={{
                        boxShadow: "0 1px 0 rgba(0, 0, 0, 0.1)",
                      }}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>

                  <button
                    className={`btn ${
                      isSuccess ? "btn-success" : "btn-primary"
                    } mb-3`}
                    disabled={!isSuccess}
                    style={{ color: "#ffffff" }}
                    onClick={handleVerifyOtp}
                  >
                    Verify
                  </button>

                  <p className="resend text-muted mb-0">
                    Didn't receive code?{" "}
                    <spam
                      onClick={handleResendClick}
                      style={{ color: "cornflowerblue", cursor: "pointer" }}
                    >
                      Re-send
                    </spam>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpVerificationModal;
